import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiKeyService } from './apikey.service';

@NgModule()
export class ApiKeyModule {
  static forRoot(): ModuleWithProviders<ApiKeyModule> {
    return {
      ngModule: ApiKeyModule,
      providers: [ApiKeyService],
    };
  }
}
