// Todas las secciones que se añadan a esta lista seran ignoradas en el gestor de errores global
// Las secciones aquí listadas deberán tener el control de errores en su respectivo sitio/componente, donde se genere el error.
export const blacklistedSections = [
  'api-keys',
  'blacklist-clients-users',
  'blacklist-ips',
  'blacklist-users',
  'clients-users-roles-permissions',
  'clients-users-roles',
  'clients-users',
  'clients',
  'logs-backoffice',
  'logs-platform',
  'users-forgot-passwords',
  'users-roles-permissions',
  'users-roles',
  'users',
];
