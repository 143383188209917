import { ModuleWithProviders, NgModule } from '@angular/core';
import { AbstractStore } from '../abstract/abstract-store.service';
import { BasicObservableService } from '../abstract/abstract.basic.service';
import { ApiKeyModule } from './apikey/apikey.module';
import { AuthModule } from './auth/auth.module';
import { BlackListClientUsersModule } from './blacklist-client-users/blacklist-client-users.module';
import { BlackListIPModule } from './blacklist-ip/blacklist-ip.module';
import { BlackListUsersModule } from './blacklist-users/blacklist-users.module';
import { ClientsRolesPermissionsModule } from './clients-roles-permissions/clients-roles-permissions.module';
import { ClientsRolesModule } from './clients-roles/clients-roles.module';
import { ClientsUsersModule } from './clients-users/clients-users.module';
import { ClientsModule } from './clients/clients.module';
import { ErrorsModule } from './error/errors.module';
import { GlobalErrorsService } from './global-errors/global-errors.service';
import { LogsBackofficeModule } from './logs-backoffice/logs-backoffice.module';
import { LogsPlatformModule } from './logs-platform/logs-platform.module';
import { TranslationsServiceModule } from './translations/translations.module';
import { UsersForgotPasswordModule } from './users-forgot-password/users-forgot-password.module';
import { UsersRolesPermissionModule } from './users-roles-permissions/users-roles-permissions.module';
import { UsersRolesModule } from './users-roles/users-roles.module';
import { UsersModule } from './users/users.module';

@NgModule({
  imports: [
    ErrorsModule.forRoot(),
    AuthModule.forRoot(),
    UsersModule.forRoot(),
    UsersForgotPasswordModule.forRoot(),
    ApiKeyModule.forRoot(),
    UsersRolesModule.forRoot(),
    UsersRolesPermissionModule.forRoot(),
    BlackListIPModule.forRoot(),
    ClientsModule.forRoot(),
    ClientsUsersModule.forRoot(),
    ClientsRolesModule.forRoot(),
    ClientsRolesPermissionsModule.forRoot(),
    BlackListClientUsersModule.forRoot(),
    BlackListUsersModule.forRoot(),
    TranslationsServiceModule.forRoot(),
    LogsBackofficeModule.forRoot(),
    LogsPlatformModule.forRoot()
  ],
})
export class AbstractServicesModules {
  static forRoot(): ModuleWithProviders<AbstractServicesModules> {
    return {
      ngModule: AbstractServicesModules,
      providers: [
        AbstractStore,
        BasicObservableService,
        GlobalErrorsService,
      ],
    };
  }
}
