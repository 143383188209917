import {
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { isTSExpired } from '@app/shared/utils/token';
import { AuthService } from '@services/abstract-services/auth/auth.service';
import { Observable, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';

export function refreshInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthService);
  const isRefreshReq =
    req.url === `${environment.api.url}/auth/refresh-token`;
  if (isRefreshReq) return next(req);

  const isRefreshing$ = authService.refreshing$().pipe(take(1));
  
  const waitForRefresh$ = authService.onRefreshSuccess$().pipe(
    take(1),
    switchMap(() => next(req))
  );


  return isRefreshing$.pipe(
    switchMap(isRefreshing => {

      // Si no tenemos tokens, continuamos con la petición.
      if (!authService.tokens?.accessToken) return next(req);

      // Si ya estamos refrescando devolvemos un observable que espera el refresh y continua con la petición.
      if (isRefreshing) return waitForRefresh$;
      
      // Si no estamos refrescando, comprobamos la caducidad del token y decidimos si refrescar.
      const expiration = authService.getAccessTokenExp();
      const isExpired = isTSExpired(expiration);

      // Si no está expirado continuamos con la petición.
      if (!isExpired) return next(req);

      // Si está expirado devolvemos la espera del refresh.
      authService.refresh();
      return waitForRefresh$;
    })
  );
}
