import { ModuleWithProviders, NgModule } from '@angular/core';
import { BlacklistUsersService } from './blacklist-users.service';


@NgModule()
export class BlackListUsersModule {
  static forRoot(): ModuleWithProviders<BlackListUsersModule> {
    return {
      ngModule: BlackListUsersModule,
      providers: [BlacklistUsersService],
    };
  }
}
