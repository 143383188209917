import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { authInterceptor } from '@app/shared/interceptors/auth.interceptor';
import { errorInterceptor } from '@app/shared/interceptors/error.interceptor';
import { refreshInterceptor } from '@app/shared/interceptors/refresh.interceptor';
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AbstractServicesModules } from '@shared/services/abstract-services/abstract-services.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { routes } from './app.routes';
import { ConfigService } from './shared/services/config/config.service';
import { CryptoServiceModule } from './shared/services/crypto/crypto.module';
import { OtpPinValidatorModule } from './shared/services/otp-pin-validate/otp-pin-validate.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initConfig(configService: ConfigService) {
  return () => configService.initConfig();
}

export const appConfig: ApplicationConfig = {
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [ConfigService],
    },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      })
    ),
    provideAnimations(),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        errorInterceptor,
        refreshInterceptor,
        authInterceptor,
      ])
    ),
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: 'es',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      CryptoServiceModule.forRoot(),
      AbstractServicesModules.forRoot(),
      OtpPinValidatorModule.forRoot(),
      NgxJsonViewerModule,
    ]),
  ],
};
