import { ModuleWithProviders, NgModule } from '@angular/core';
import { LogsPlatformService } from './logs-platform.service';

@NgModule()
export class LogsPlatformModule {
  static forRoot(): ModuleWithProviders<LogsPlatformModule> {
    return {
      ngModule: LogsPlatformModule,
      providers: [LogsPlatformService],
    };
  }
}
