import { ModuleWithProviders, NgModule } from '@angular/core';
import { UsersRolesService } from './users-roles.service';

@NgModule()
export class UsersRolesModule {
  static forRoot(): ModuleWithProviders<UsersRolesModule> {
    return {
      ngModule: UsersRolesModule,
      providers: [UsersRolesService],
    };
  }
}
