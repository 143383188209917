import { Component, OnInit, viewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/abstract-services/auth/auth.service';
import { GlobalErrorsService } from '@services/abstract-services/global-errors/global-errors.service';
import { BasicObservableService } from '@services/abstract/abstract.basic.service';
import { MessageService } from 'primeng/api';
import { GlobalErrorComponent } from './shared/components/modals/global-error/global-error.component';
import { PrimeNGModule } from './shared/components/primeng/primeng.module';
import {
  AbstractEvent,
  GlobalMessage,
} from './shared/interfaces/abstract/events';
import { AbstractStore } from './shared/services/abstract/abstract-store.service';
import { CryptoService } from './shared/services/crypto/crypto.service';
import { OtpPinValidatorService } from './shared/services/otp-pin-validate/otp-pin-validate.service';
import { getPreferredScheme } from './shared/utils/theme';

@UntilDestroy()
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, GlobalErrorComponent, PrimeNGModule],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private globalErrorModal = viewChild.required(GlobalErrorComponent);

  // Declaración de servicios que necesitan inicializarse desde el inicio
  constructor(
    private store: AbstractStore,
    private authService: AuthService,
    private cryptoService: CryptoService,
    private translate: TranslateService,
    private bOBService: BasicObservableService,
    private globalErrorsService: GlobalErrorsService,
    private messageService: MessageService,
    private otpPinValidatorService: OtpPinValidatorService
  ) {}

  ngOnInit(): void {
    //this.initTheme();
    this.initCipher();
    this.initTranslate();
    this.initGlobalErrors();
    this.initUser();
  }

  initTheme(): void {
    const scheme =
      environment.defaultTheme === 'auto'
        ? getPreferredScheme().toUpperCase()
        : environment.defaultTheme;

    // Cuando el dark mode este completado, implementarlo en el login.
    //document.body.setAttribute('data-theme', scheme);
    this.store.set(AbstractEvent.SET_THEME, scheme);
  }

  initTranslate(): void {
    this.translate.setDefaultLang(environment.defaultLang);
    this.translate.use(environment.defaultLang);
  }

  initUser(): void {
    const hadTokens = this.authService.loadTokens();
    if (hadTokens) {
     const { interfaceLanguage, interfaceTheme } = this.authService.user;
     if(interfaceLanguage) this.translate.use(interfaceLanguage.toLowerCase());
     if(interfaceTheme) this.store.set(AbstractEvent.SET_THEME, interfaceTheme.toLowerCase());
    }
  }

  async initCipher(): Promise<void> {
    // Este servicio se puede inicializar desde el app.config con sus parametros.
    // Pero necesitamos inicializarlo aquí porque las variables de entorno las cargamos
    // en remoto para que sean dinámicas y la aplicación debe iniciar antes que
    // la configuración.
    await this.cryptoService.init(
      environment.cipher.secret,
      environment.cipher.iv
    );
  }

  initGlobalErrors(): void {
    this.store
      .getAsEvent(AbstractEvent.BACKEND_OFFLINE)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.globalErrorModal().showModal());

    this.store
      .getAsEvent<GlobalMessage>(AbstractEvent.GLOBAL_MESSAGE)
      .pipe(untilDestroyed(this))
      .subscribe(message => {
        this.showMessage(
          message?.message,
          message?.title,
          message?.severity,
          message?.life
        );
      });
  }

  showMessage(
    content: string,
    title: string,
    severity: string = 'success',
    life: number = 5000
  ) {
    this.messageService.add({
      severity,
      summary: title,
      detail: content,
      life,
    });
  }
}
