import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslationsService } from './translations.service';


@NgModule()
export class TranslationsServiceModule {
  static forRoot(): ModuleWithProviders<TranslationsServiceModule> {
    return {
      ngModule: TranslationsServiceModule,
      providers: [TranslationsService],
    };
  }
}
