import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  MaybeAsync,
  RedirectCommand,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@services/abstract-services/auth/auth.service';
import { map, of, switchMap } from 'rxjs';
import { isTSExpired } from '../utils/token';

enum RedirectRoutes {
  LOGIN = '/auth/login',
  DASHBOARD = '/admin'
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): MaybeAsync<GuardResult> {
    if (this.authService.isLogged) {
      const expiration = this.authService.getAccessTokenExp();
      const isExpired = isTSExpired(expiration);
      
      if(isExpired) return false

      return this.authService
        .refreshing$()
        .pipe(
          switchMap((refreshing) => refreshing 
            ? this.authService.onRefreshSuccess$().pipe(map(() => true))
            : of(true)),
        );
    };
    const nextUrlTree = this.router.parseUrl(RedirectRoutes.LOGIN);
    return new RedirectCommand(nextUrlTree, {
      skipLocationChange: true,
    });
  }
}
