import { ModuleWithProviders, NgModule } from '@angular/core';
import { UsersRolesPermissionService } from './users-roles-permissions.service';

@NgModule()
export class UsersRolesPermissionModule {
  static forRoot(): ModuleWithProviders<UsersRolesPermissionModule> {
    return {
      ngModule: UsersRolesPermissionModule,
      providers: [UsersRolesPermissionService],
    };
  }
}
