import { ModuleWithProviders, NgModule } from '@angular/core';
import { BlacklistIPService } from './blacklist-ip.service';


@NgModule()
export class BlackListIPModule {
  static forRoot(): ModuleWithProviders<BlackListIPModule> {
    return {
      ngModule: BlackListIPModule,
      providers: [BlacklistIPService],
    };
  }
}
