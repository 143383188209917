import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, throwError } from 'rxjs';
import { AbstractStore } from '@services/abstract/abstract-store.service';
import { AbstractEvent } from '@interfaces/abstract/events';

export function errorInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const store = inject(AbstractStore);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 0) {
        const isApiRequest = req.url.includes(environment.api.url);
        if (isApiRequest) store.set(AbstractEvent.BACKEND_OFFLINE);
      }
      return throwError(() => error);
    })
  );
}
