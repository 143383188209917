import { ModuleWithProviders, NgModule } from '@angular/core';
import { LogsBackofficeService } from './logs-backoffice.service';

@NgModule()
export class LogsBackofficeModule {
  static forRoot(): ModuleWithProviders<LogsBackofficeModule> {
    return {
      ngModule: LogsBackofficeModule,
      providers: [LogsBackofficeService],
    };
  }
}
