import { ModuleWithProviders, NgModule } from '@angular/core';
import { BlacklistClientUsersService } from './blacklist-client-users.service';


@NgModule()
export class BlackListClientUsersModule {
  static forRoot(): ModuleWithProviders<BlackListClientUsersModule> {
    return {
      ngModule: BlackListClientUsersModule,
      providers: [BlacklistClientUsersService],
    };
  }
}
