import { ModuleWithProviders, NgModule } from '@angular/core';
import { UsersService } from './users.service';
import { CommonModule } from '@angular/common';

@NgModule()
export class UsersModule {
  static forRoot(): ModuleWithProviders<UsersModule> {
    return {
      ngModule: UsersModule,
      providers: [UsersService],
    };
  }
}
