import { Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/public/public.routes').then(
        m => m.PublicRoutingModule
      ),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/admin/admin.routes').then(m => m.AdminRoutingModule),
  },
];
