<p-dialog
  [closeOnEscape]="false"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visible"
  [dismissableMask]="false"
  [style]="{ 'max-width': '430px' }"
  (onHide)="this.hideModal()"
>
  <ng-template pTemplate="headless">
    <div
      class="bg-white rounded-md p-8 grid gap-6 justify-items-center"
    >
      <div
        class="flex gap-3 items-center text-red-500 justify-center"
      >
        <i class="fa-solid fa-warning"></i>
        <h2 class="text-center text-lg font-semibold">
          Problemas de conexión
        </h2>
      </div>
      <img src="assets/images/gif/offline.gif" />
      <span class="p-text-secondary font-bold text-md block mb-3"
        >Estamos trabajando en solucionar un problema de conexión con
        nuestros servidores.</span
      >
      <span class="p-text-secondary text-md block mb-3"
        >Intentelo mas tarde o espere aquí, la aplicación intentará
        recuperar la conexión automáticamente cada 10 segundos.</span
      >
      <p-button
        class="text-center"
        label="Reintentar"
        icon="fa-solid fa-refresh"
        styleClass="p-button--default"
        [disabled]="authLoading$ | async"
        (onClick)="refresh(true)"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
