import { ModuleWithProviders, NgModule } from '@angular/core';
import { ClientsRolesPermissionsService } from './clients-roles-permissions.service';


@NgModule()
export class ClientsRolesPermissionsModule {
  static forRoot(): ModuleWithProviders<ClientsRolesPermissionsModule> {
    return {
      ngModule: ClientsRolesPermissionsModule,
      providers: [ClientsRolesPermissionsService],
    };
  }
}
