import { ModuleWithProviders, NgModule } from '@angular/core';

import { DialogService } from 'primeng/dynamicdialog';
import { OtpPinValidatorService } from './otp-pin-validate.service';

@NgModule()
export class OtpPinValidatorModule {
  static forRoot(): ModuleWithProviders<OtpPinValidatorModule> {
    return {
      ngModule: OtpPinValidatorModule,
      providers: [OtpPinValidatorService, DialogService],
    };
  }
}
