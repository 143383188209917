import { CommonModule } from '@angular/common';
import { Component, inject, model } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/services/abstract-services/auth/auth.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription, debounceTime, filter, interval } from 'rxjs';
import { PrimeNGModule } from '../../primeng/primeng.module';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-global-error',
  standalone: true,
  imports: [CommonModule, PrimeNGModule],
  templateUrl: './global-error.component.html',
})
export class GlobalErrorComponent {
  visible = model(false);
  router = inject(Router);
  authService = inject(AuthService);
  authLoading$ = this.authService.loading$();
  onRefresh = this.authService
    .onRefreshSuccess$()
    .pipe(
      filter(() => this.visible()),
      debounceTime(500)
    )
    .subscribe(this.hideModal.bind(this));

  reloadInterval!: Subscription;

  showModal(): void {
    this.visible.update(() => true);
    this.reloadInterval = interval(10000).subscribe(
      () => this.refresh()
    );
  }

  hideModal(): void {
    if(this.reloadInterval) this.reloadInterval.unsubscribe();
    this.visible.update(() => false);
    location.reload();
  }

  refresh(click: boolean = false): void {
    if (this.authService.isLogged && !click) return;
    this.reloadInterval.unsubscribe();
    location.reload();
  }
}
