import { ModuleWithProviders, NgModule } from '@angular/core';
import { ClientsRolesService } from './clients-roles.service';


@NgModule()
export class ClientsRolesModule {
  static forRoot(): ModuleWithProviders<ClientsRolesModule> {
    return {
      ngModule: ClientsRolesModule,
      providers: [ClientsRolesService],
    };
  }
}
