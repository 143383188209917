import { ModuleWithProviders, NgModule } from '@angular/core';
import { ClientsService } from './clients.service';


@NgModule()
export class ClientsModule {
  static forRoot(): ModuleWithProviders<ClientsModule> {
    return {
      ngModule: ClientsModule,
      providers: [ClientsService],
    };
  }
}
