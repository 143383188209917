import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@app/shared/interfaces/abstract/query.params';
import { IFindLogBackofficeByCriteriaItem } from '@shared-global/interfaces/logs-backoffice/interfaces/find-logs-backoffice-by-criteria-response.interface';
import { IFindLogsPlatformByCriteriaRequest } from '@shared-global/interfaces/logs-platform/interfaces/find-logs-platform-by-criteria-request.interface';
import { ILogPlatform } from '@shared-global/interfaces/logs-platform/interfaces/log-platform.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable()
export class LogsPlatformService extends AbstractCrudService<
  ILogPlatform | IFindLogBackofficeByCriteriaItem,
  IFindLogsPlatformByCriteriaRequest & QueryParamsInterface
> {
  protected override feature = 'logs-platform';
  protected override endPoint = `/logs-platform/`;
}
