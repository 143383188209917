import { environment } from '@environments/environment';

export const apiKeyEndpoints: string[] = ['users', 'auth/login'];

export const apiKeyRequests = () =>
  apiKeyEndpoints.map(
    endpoint => `${environment.api.key}/${endpoint}/`
  );

export const isApiRequest = (url: string): boolean => {
  return url.startsWith(environment.api.url);
};

export const isApiKeyRequest = (url: string): boolean => {
  return apiKeyRequests().includes(url);
};
