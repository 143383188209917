import { ModuleWithProviders, NgModule } from '@angular/core';
import { UsersForgotPasswordService } from './users-forgot-password.service';

@NgModule()
export class UsersForgotPasswordModule {
  static forRoot(): ModuleWithProviders<UsersForgotPasswordModule> {
    return {
      ngModule: UsersForgotPasswordModule,
      providers: [UsersForgotPasswordService],
    };
  }
}
