import { ModuleWithProviders, NgModule } from '@angular/core';
import { ClientsUsersService } from './clients-users.service';


@NgModule()
export class ClientsUsersModule {
  static forRoot(): ModuleWithProviders<ClientsUsersModule> {
    return {
      ngModule: ClientsUsersModule,
      providers: [ClientsUsersService],
    };
  }
}
